@import 'mastodon/mixins';
@import 'mastodon/variables';
@import 'fonts/roboto';
@import 'fonts/roboto-mono';

@import 'mastodon/reset';
@import 'mastodon/basics';
@import 'mastodon/branding';
@import 'mastodon/containers';
@import 'mastodon/lists';
@import 'mastodon/widgets';
@import 'mastodon/forms';
@import 'mastodon/accounts';
@import 'mastodon/components';
@import 'mastodon/polls';
@import 'mastodon/modal';
@import 'mastodon/emoji_picker';
@import 'mastodon/annual_reports';
@import 'mastodon/about';
@import 'mastodon/tables';
@import 'mastodon/admin';
@import 'mastodon/dashboard';
@import 'mastodon/rtl';
div.column {
	          flex-grow: 1;
}
@import 'mastodon/accessibility';
@import 'mastodon/rich_text';
